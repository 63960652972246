import type { ComponentProps } from 'react'

import { cn } from '#utils/utils'

export function H1(props: ComponentProps<'h1'>) {
  return (
    <h1
      {...props}
      className={cn('scroll-m-20 text-3xl font-heading', props.className)}
    />
  )
}

export function H2(props: ComponentProps<'h2'>) {
  return (
    <h2
      {...props}
      className={cn(
        'scroll-m-20 text-xl font-semibold tracking-tight first:mt-0',
        props.className
      )}
    />
  )
}

export function H3(props: ComponentProps<'h3'>) {
  return (
    <h3
      {...props}
      className={cn(
        'scroll-m-20 text-lg font-semibold tracking-tight',
        props.className
      )}
    />
  )
}
